import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoginModel} from "../../models/login";
import {GlobalVariables} from "../../service/GlobalVariables";
import {roleModel} from "../../models/roles";


@Injectable()
export class LoginProvider {


  constructor(public http: HttpClient, public gb: GlobalVariables) {

  }


  async getLogin(usuario, contra, email) {
    //console.log('ZXC LOGIN TEST')
    let up = new LoginModel();
    up.userName = usuario;
    let promiseToken = this.http.post(this.gb.getApiUrlLoginCheck(), JSON.stringify({"username":usuario,"password":contra})).toPromise();
    let token = "";
    await promiseToken.then(dataToken => {
      token = dataToken["token"];
    });

    up.cargo = '';
    //return this.http.get(this.loginApiUrl,{headers: {'Content-Type':'application/json','Accept':'application/json','Access-Control-Allow-Origin': '*'}, responseType:"json"});

    let logindata = new LoginModel();
    logindata.userName = usuario;
    logindata.userPassword = contra;

    let headers = new HttpHeaders({"Accept": 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
    let promise = this.http.post(this.gb.getApiUrlLogin(), {}, {headers}).toPromise();

    promise.then(data => {

      if (data["retorno"] == 0) {
        up.id = data["userModel"]["id"];
        up.cargo = data["userModel"]["cargo"];
        up.firma = data["firma"];
        console.log(up);
        data["up"] = up;
        data["token"] = token;
        data["verificador"] = true;
      }
      else {
        data["verificador"] = false;
      }

      return data;
    });
    promise.catch(function (reason) {
      var data = {};
      data["retorno"] = "1";
      data["verificador"] = false;

      return data;
    });

    return promise;
  }

}
