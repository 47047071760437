import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RestProvider } from './providers/rest/rest';
import { GlobalVariables } from './service/GlobalVariables';
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { LoginProvider } from './providers/login/login';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { CheckProvider } from './providers/check/check';
import { File } from '@ionic-native/file/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    IonicStorageModule.forRoot()
],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Camera, RestProvider, Geolocation, GlobalVariables, LoginProvider, CheckProvider, File],
  bootstrap: [AppComponent],
})
export class AppModule {}
