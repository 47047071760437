import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { RestProvider } from '../providers/rest/rest';
 
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private restProvider: RestProvider, private router: Router) { }
 
  canLoad(): boolean {    
    if (this.restProvider.Credenciales.id) {
        return true;
    } else {
        this.router.navigateByUrl('/login');
        return false;
    }
  }
}