import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {RespuestaCuestionarioModel} from "../../models/respuesta-cuestionario";
import { GlobalVariables } from "../../service/GlobalVariables";
import { respuestasModel } from "../../models/respuesta-pregunta";

@Injectable()
export class CheckProvider {

  constructor(public http: HttpClient , public gb:GlobalVariables ) {
  }

  /*getPats(){
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json'});
    return this.http.get(this.gb.getApiUrlVehiculos(),{headers});
  }*/

  getPats(idEmpresa){
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json','Authorization': 'Bearer ' + this.gb.token});
    return this.http.get(this.gb.getApiUrlListadoVehiculos()+idEmpresa,{headers});
  }
  
  getPreguntas(idVehiculo){
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json','Authorization': 'Bearer ' + this.gb.token});
    return this.http.get(this.gb.getApiUrlPreguntas()+idVehiculo,{headers});
    //return this.http.get(this.baseUrl+this.ApiUrlPreguntas,{headers});
  }

  getCuestionarios(idGrupo){
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json','Authorization': 'Bearer ' + this.gb.token});
    return this.http.get(this.gb.getApiUrlCuestionarios()+idGrupo + "/3",{headers});
    //return this.http.get(this.baseUrl+this.ApiUrlPreguntas,{headers});
  }

  postFirmaSupervisor(idUser, firma){
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json','Authorization': 'Bearer ' + this.gb.token});
    return this.http.post(this.gb.setApiUrlFirmaSupervisor(),JSON.stringify({"idUser":idUser, "firma": firma}),{headers});
    //return this.http.get(this.baseUrl+this.ApiUrlPreguntas,{headers});
  }
  
  postCheck(c : RespuestaCuestionarioModel){
    let ccopy = JSON.parse(JSON.stringify(c));
    for (let i = 0; i < ccopy.respuestas.length; i++) {
      ccopy.respuestas[i].foto = null;
    }
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json','Authorization': 'Bearer ' + this.gb.token});
    return this.http.post(this.gb.getApiUrlCuestionario(), JSON.stringify(ccopy),{headers});
  }


  postCheckRespuestas( respuesta : respuestasModel){
    //console.log('datos RespuestaCuestionarioModel')
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json','Authorization': 'Bearer ' + this.gb.token});
    return this.http.post(this.gb.getApiUrlRespuesta(), JSON.stringify(respuesta),{headers});
    //return this.http.post(this.baseUrl+this.ApiUrlRespuesta, JSON.stringify(c),{headers});
  }

  checkRespuestasCriticas(idCustionario){
    //console.log('ZXC ENTRE!!' + idCustionario);
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json','Authorization': 'Bearer ' + this.gb.token});
    return this.http.get(this.gb.getApiUrlCheckRespuestasCriticas()+idCustionario,{headers});
    //return this.http.get(this.baseUrl+this.ApiUrlPreguntas,{headers});

  }

  getIdEmpresa(nombreUsuario){
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json','Authorization': 'Bearer ' + this.gb.token});
    return this.http.get(this.gb.getApiUrlIdEmpresa()+nombreUsuario,{headers});
    //return this.http.get(this.baseUrl+this.ApiUrlPreguntas,{headers});

  }

  getValidarViaje() {
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json','Authorization': 'Bearer ' + this.gb.token});
    return this.http.get(this.gb.getApiUrlValidarViaje(),{headers});
  }

  getValidarIdViaje(idViaje: Number) {
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json','Authorization': 'Bearer ' + this.gb.token});
    return this.http.get(this.gb.getApiUrlValidarIdViaje()+idViaje,{headers});
  }

  getValidarEstado(idViaje: Number) {
    let headers = new HttpHeaders({"Accept":'application/json','Content-Type':'application/json','Authorization': 'Bearer ' + this.gb.token});
    return this.http.get(this.gb.getValidarEstado()+idViaje,{headers});
  }
}

