import {PreguntaModel} from "./pregunta";
import {SeccionModel} from "./seccion";

export class CuestinarioModel{

  public id:number;
  public nombre:string;
  public idViaje:number;
  public descripcion:string;
  public preguntas:Array<PreguntaModel>;
  public secciones:Array<SeccionModel>;
  public requiereVehiculo:boolean;
  public requiereFirma:boolean;
  public firma:string = null;
  public modulo:string = null;
  public habilitado:string = null;
  public configuracionApp:{completarDatosCliente:boolean, completarDatosObservado: boolean} = {completarDatosCliente:true, completarDatosObservado:true};

}

