import {Injectable, NgZone} from '@angular/core';
import {ToastController, Platform, LoadingController} from "@ionic/angular";
import {Geolocation} from "@ionic-native/geolocation/ngx";
import {LoginProvider} from "../login/login";
import {Storage} from "@ionic/storage";
import {LoginModel} from "../../models/login";
import {RespuestaCuestionarioModel} from "../../models/respuesta-cuestionario";
import {VehiculoModel} from "../../models/vehiculo";
import {CuestinarioModel} from "../../models/cuestinario";
import {UbicacionModel} from "../../models/ubicacion";
import {CheckProvider} from "../check/check";
import {respuestasModel} from "../../models/respuesta-pregunta";
//import SJS from "safe-json-stringify";

/*
  Generated class for the RestProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class RestProvider {

  //Inicializar credenciales
  public Credenciales: LoginModel = {id: null, userName: '', userPassword: '', cargo: '', firma: ''};

  public Viaje = {destino:'',origen:'',hora:'',idViaje:null};
  //Obetener Patentes
  public Patentes: Array<VehiculoModel> = [];
  //Guardar Cuestionario(PREGUNTAS)
  public nPregunta: number;
  public Check = new CuestinarioModel();
  //Iniciar contador de preguntas
  public preguntasCounter: number = 0;
  public porcentajeCheck: number = 0;
  //Datos de latitud longitud y tiempo checklist
  public templat: number = 0;
  public temlng: number = 0;
  public temtime: number = 0;
  //Patente a la que imputar checklist
  public idVehiculo: number = 0;
  //Paths de ultimo archivo multimedia  capturado
  public fotopath: string = "";
  public videopath: string = "";
  public videopathpreview: string = "";
  public audiopathpreview: string = "";
  public fotopathpreview: string = "";
  public audiopath: string = "";
  public pathEnviado: string = "";
  //Tipo de archivo a cargar al abrir preview
  public tipoDeMultimedia: string = '';
  //Aqui se almacenan las respuestas del cuestionario
  public respCues: RespuestaCuestionarioModel;
  //Beacons
  public beaconChange: boolean = false;
  public delegate: any;
  public estadobeacon: string = "Desactivado";
  public beaconInit: boolean = false;
  public region: any;
  public zone: any;
  public beacons = [];
  public bnumber = 0;
  public croni;
  public isGoogleAccount: boolean = false;
  public isLastCheckList: boolean = false;
  public inicioViaje: string = "";
  public finViaje: string = "";
  public copiloto: string = "";
  public observaciones: string = "";
  enviandoCuestionarios:boolean = false;
  timerCuestionario: any;
  
  public loading;

  constructor(public geolocation: Geolocation, 
              public platform: Platform, 
              public loginProvider: LoginProvider, 
              public storage: Storage, 
              public checkProvider: CheckProvider, 
              public toastCtrl: ToastController,
              public loadingCtrl: LoadingController) {
    this.zone = new NgZone({enableLongStackTrace: false});
    this.iniciarTimerCuestionario();

    this.loading = this.loadingCtrl.create({
      message: 'Por favor espere ...',
      cssClass: 'spinner'
    });
  }

  //get & set de la base de datos de la aplicacion.
  getLoginLocalDB(key) {
    this.storage.get(key).then(
      (data) => {
        this.Credenciales.userName = data.userName;
        this.Credenciales.userPassword = data.userPassword;
        this.Credenciales.id = data.id;
        this.Credenciales.cargo = data.cargo;
        this.Credenciales.firma = data.firma;
      });
  }

  setLocalDB(key, value) {
    this.storage.set(key, value);
  }

  getLocalDB(key) {
    return this.storage.get(key);
  }

  removeLocalDB(key) {
    return this.storage.remove(key);
  }

  iniciarTimerCuestionario(){
    clearInterval(this.timerCuestionario);
    this.timerCuestionario = setInterval( () => {
      this.getKeys();
    }, 30000);
  }

  getKeys() {
    console.log("Enviando cuestionarios...");
    if (!this.enviandoCuestionarios) {
    this.storage.forEach((v, k) => {
      if (k.includes("CheckNoUp")) {
        this.checkProvider.postCheck(v).subscribe(
          async (data) => {
            let respuestas: Array<respuestasModel> = v.respuestas;
            let response = await this.enviarRespuestas(respuestas, data['idCuestionario']);
            if (response) {
              this.storage.remove(k);
              this.toastCtrl.create({
                message: 'Cuestionario subido.',
                position: 'middle',
                duration: 3000
              }).then((toastData) => {
                toastData.present();
              });
            }
          },
          (err) => {
          }
        );
      }
    });
  }
  this.enviandoCuestionarios = false;
  }

  getMarcaTemporal() {
    return new Promise((resolve,reject)=>{
      //this.loading.present();
      console.log('getCurrentPosition');
      this.respCues.idCuestionario = this.Check.id;
      this.respCues.idUser = this.Credenciales.id;
      this.respCues.idVehiculo = this.idVehiculo;
      this.respCues.fechaHora = new Date((new Date()).getTime()- ((new Date()).getTimezoneOffset() / 60)*3600*1000).toJSON();
      this.respCues.fechaRespuesta = new Date((new Date()).getTime()- ((new Date()).getTimezoneOffset() / 60)*3600*1000).toJSON();
      this.respCues.ubicacion = new UbicacionModel();
      this.respCues.ubicacion.latitud = 0;
      this.respCues.ubicacion.longitud = 0;
      this.respCues.firma = this.Check.firma;
      this.respCues.latitud = 0;
      this.respCues.longitud = 0;
      this.respCues.idViaje = this.Check.idViaje;
      let respuestas: Array<respuestasModel> = this.respCues.respuestas;
      this.geolocation.getCurrentPosition({ timeout: 20000, maximumAge: 300000}).then((resp) => {
        this.respCues.latitud = resp.coords.latitude;
        this.respCues.longitud = resp.coords.longitude;
        this.respCues.ubicacion.latitud = resp.coords.latitude;
        this.respCues.ubicacion.longitud = resp.coords.longitude;
        this.temtime = resp.timestamp;
        
      }).catch((error) => {
        console.log("TIMEOUT");
      }).finally(() => {
        this.checkProvider.postCheck(this.respCues).subscribe(
          async (data) => {
            this.toastCtrl.create({
              message: 'Cuestionario subido. Las imágenes se cargarán en segundo plano',
              position: 'middle',
              duration: 3000
            }).then((toastData) => {
              console.log(toastData);
              toastData.present();
            });
            let response = await this.enviarRespuestas(respuestas, data['idCuestionario']);
            if (!response) {
              this.setLocalDB("CheckNoUp " + this.respCues.fechaHora.toString(), this.respCues);
            }
            resolve();
          },
          (err) => {
            this.toastCtrl.create({
              message: 'Cuestionario no se pudo subir, guardado localmente.',
              position: 'middle',
              duration: 3000
            }).then((toastData) => {
              console.log(toastData);
              toastData.present();
            });
            this.setLocalDB("CheckNoUp " + this.respCues.fechaHora.toString(), this.respCues);
            resolve();
          }
        );
      });
    });
  }

  async enviarRespuestas(respuestas, idCuestionario) {
    let upload = true;
    try {
      for (let m of respuestas) {
        m.idCuestionario = idCuestionario;
        await this.checkProvider.postCheckRespuestas(m).toPromise();
      }
    } catch (err) {
      upload = false;
    }
    return upload;
  }

  enviarPath(path, tipo) {
    this.tipoDeMultimedia = tipo;
    console.log(this.tipoDeMultimedia);

    path = path.substr( path.indexOf('base64,') + 7);
    console.log( path.substring(0,200) );

    if (tipo === "Foto") {
      this.fotopath = path;
    }
  }

  getFoto(){
    //return "data:image/jpeg;base64," + this.fotopath;
    return this.fotopathpreview;
  }

  getPath(tipo) {
    if (tipo === "Foto") {
      return this.fotopath
    }
  }
}
