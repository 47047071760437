import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'prefix'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'checklist',
    loadChildren: () => import('./pages/checklist/checklist.module').then( m => m.ChecklistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'preview',
    loadChildren: () => import('./pages/preview/preview.module').then( m => m.PreviewPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'modulos',
    loadChildren: () => import('./pages/modulos/modulos.module').then( m => m.ModulosPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'submodulos',
    loadChildren: () => import('./pages/submodulos/submodulos.module').then( m => m.SubmodulosPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'resumen',
    loadChildren: () => import('./pages/resumen/resumen.module').then( m => m.ResumenPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'instrucciones',
    loadChildren: () => import('./pages/instrucciones/instrucciones.module').then( m => m.InstruccionesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'checklist-one-list',
    loadChildren: () => import('./pages/checklist-one-list/checklist-one-list.module').then( m => m.ChecklistOneListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'firma-supervisor',
    loadChildren: () => import('./pages/firma-supervisor/firma-supervisor.module').then( m => m.FirmaSupervisorPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/login'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
