import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker/';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private swUpdate: SwUpdate) {
    this.initializeApp();
  }

  initializeApp(): void {
    if (this.swUpdate.available) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('Una nueva versión está disponible. Desea actualizar?'))
          window.location.reload();
      });
    }
  }
}
