import { Injectable } from '@angular/core';

@Injectable()
export class GlobalVariables {

  //public baseUrl:string = "https://back.mel.optimal.cl/";
  //public baseUrl:string = "http://192.168.100.101:5032/"; //localhost
  
  public baseUrl:string = "https://api.checklist.optimal.cl/"; // Prod
  // public baseUrl:string = "http://localhost:8000/"; // Local
  // public baseUrl:string = "https://qa.api.checklist.optimal.cl/"; //QA
  
  public token:string = "";
  public apiUrlVehiculos:string  = "api/v2/Vehiculo/PPUCheck";
  public apiUrlListadoVehiculos:string  = "api/v2/Vehiculo/ListadoVehiculosXEmpresa/";
  public apiUrlPreguntas:string  = "api/v2/Cuestionario/obtenerPrimerCuestionarioPorIdV2/";
  public apiUrlCuestionarios:string  = "api/v2/Cuestionario/obtenerGrupoPorIdInacal/";
  public apiUrlRespuesta:string  = "api/v2/Cuestionario/guardarCuestionario/";
  public apiUrlFirmaSupervisor:string  = "api/v2/user/update_firma";

  public apiUrlLogin:string = "api/v2/Account/loginMobile";
  public apiUrlLoginCheck:string = "api/v2/login_check";
  public apiUrlValidarViaje:string = "api/v2/inacal/validar/viaje";
  public apiUrlValidarIdViaje:string = "api/v2/inacal/validar/";
  public apuUrlValidarEstado:string = "api/v2/inacal/validar/estado/";


  public apiUrlGuardarSoloCuestionario:string  = "api/v2/Cuestionario/guardarSoloCuestionario/";
  public apiUrlguardarRespuestasCuestionario:string  = "api/v2/Cuestionario/guardarRespuestasCuestionario/";
  public apiUrlCheckRespuestasCriticas:string  = "api/v2/Cuestionario/checkRespuestasCriticas/";

  public ApiUrlIdEmpresa:string = "api/v2/ContratoPersona/getIdEmpresa/";

  constructor(){

  }

  getApiUrlVehiculos(){
    return this.baseUrl+this.apiUrlVehiculos;
  }

  getApiUrlListadoVehiculos(){
    return this.baseUrl+this.apiUrlListadoVehiculos;
  }

  getApiUrlPreguntas(){
    return this.baseUrl+this.apiUrlPreguntas;
  }

  getApiUrlCuestionarios(){
    return this.baseUrl+this.apiUrlCuestionarios;
  }

  setApiUrlFirmaSupervisor(){
    return this.baseUrl+this.apiUrlFirmaSupervisor;
  }

  getApiUrlRespuestas(){
    return this.baseUrl+this.apiUrlRespuesta;
  }

  getApiUrlLogin(){
    return this.baseUrl+this.apiUrlLogin;
  }

  getApiUrlCuestionario(){
    return this.baseUrl+this.apiUrlGuardarSoloCuestionario;
  }

  getApiUrlRespuesta(){
    return this.baseUrl+this.apiUrlguardarRespuestasCuestionario;
  }

  getApiUrlCheckRespuestasCriticas(){
    return this.baseUrl+this.apiUrlCheckRespuestasCriticas;
  }

  getApiUrlIdEmpresa(){
    return this.baseUrl+this.ApiUrlIdEmpresa;
  }

  getApiUrlLoginCheck(){
    return this.baseUrl+this.apiUrlLoginCheck;
  }

  getApiUrlValidarViaje(){
    return this.baseUrl+this.apiUrlValidarViaje;
  }

  getApiUrlValidarIdViaje(){
    return this.baseUrl+this.apiUrlValidarIdViaje;
  }
  getValidarEstado() {
    return this.baseUrl+this.apuUrlValidarEstado;
  }
}
